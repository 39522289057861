import * as openpgp from 'openpgp';

export const encrypt = async (text: string, key: string): Promise<string> => {
	if (!text) return '';
	const publicKey = await openpgp.readKey({ armoredKey: key });
	const encrypted: any = await openpgp.encrypt({
		message: await openpgp.createMessage({ text: text.toString() || '' }),
		encryptionKeys: publicKey,
	});
	const text_base64: string = btoa(encrypted);
	return text_base64;
};

export const encodeAllJSONData = async (bodyEncrypted: any, keyValue: string) => {
	const body: any = {};
	try {
		if (typeof bodyEncrypted === 'object') {
			for (const key in bodyEncrypted) {
				const valueEncrypted = bodyEncrypted[key];
				if (valueEncrypted === '') {
					body[key] = '';
				} else if (Array.isArray(valueEncrypted)) {
					const array = [];
					for (const element of valueEncrypted) {
						if (element === '') {
							array.push('');
						} else if (typeof element === 'string') {
							array.push(await encrypt(element, keyValue));
						} else if (typeof element === 'object') {
							array.push(await encodeAllJSONData(element, keyValue));
						}
					}
					body[key] = array;
				} else if (typeof valueEncrypted === 'object') {
					body[key] = await encodeAllJSONData(valueEncrypted, keyValue);
				} else {
					body[key] = await encrypt(valueEncrypted, keyValue);
				}
			}
		}
	} catch (error) {
		throw new Error('Error al encriptar');
	}
	return body;
};

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';

import ActualStep from '../assets/svg/checkbox-circle-actual.svg';
import CompletedStep from '../assets/svg/checkbox-circle-completed.svg';
import PendingStep from '../assets/svg/checkbox-circle-pending.svg';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: 'calc(-50%)',
		right: 'calc(50%)',
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#293990',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#293990',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderTopWidth: 3,
		borderRadius: 1,
	},
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
	({ theme, ownerState }) => ({
		color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
		display: 'flex',
		height: 22,
		alignItems: 'center',
		zIndex: 2,
		...(ownerState.active && {
			color: '#293990',
		}),
		'& .QontoStepIcon-completedIcon': {
			color: '#293990',
			zIndex: 1,
			fontSize: 18,
		},
		'& .QontoStepIcon-circle': {
			width: 8,
			height: 8,
			borderRadius: '50%',
			backgroundColor: 'currentColor',
		},
		'& .Mui-active': {
			backgroundColor: '#293990',
		},
	})
);

function QontoStepIcon(props: StepIconProps) {
	const { active, completed, className } = props;

	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? <img src={ActualStep} alt="actual-icon" /> : null}
			{active ? <img src={CompletedStep} alt="completed-icon" /> : null}
			{!completed && !active ? (
				<img src={PendingStep} alt="completed-icon" />
			) : null}
		</QontoStepIconRoot>
	);
}

interface IStepper {
	step: number;
	steps: Array<string>;
}

export default function CustomizedSteppers(props: IStepper) {
	const { step, steps } = props;
	return (
		<Stack sx={{ width: '100%' }} spacing={4}>
			<Stepper alternativeLabel activeStep={step} connector={<QontoConnector />}>
				{steps.map((label) => (
					<Step
						key={label}
						sx={(theme) => ({
							[theme.breakpoints.down(2160)]: {
								'& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
									fontWeight: '400',
									fontSize: '18px',
									marginTop: '10px',
									fontFamily: 'Geomanist',
								},
								'& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
									{
										color: '#293990',
										fontWeight: '500',
										fontSize: '18px',
									},
							},
							[theme.breakpoints.down('sm')]: {
								'& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel':
									{
										display: 'none',
									},
								'& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
									{
										display: 'none',
									},
							},
						})}
					>
						<StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
		</Stack>
	);
}

import { useEffect, useState } from 'react';
import {
	IActiveAccountResponse,
	IActiveRegisterInputs,
	IActiveRegisterParams,
	IModal,
} from '../interfaces/activate-register.interface';
import { compareEmails, emailValid, nrpValid } from '../../../utils/validators';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { RESPONSE_CODES } from '../../../config/response-codes';

export function useActiveRegister() {
	const [codeInput, setCodeInput] = useState('');
	const [validForm, setValidForm] = useState(true);
	const [openModal, setOpenModal] = useState(true);
	const [code, setCode] = useState('');
	const [refreshCaptcha, setRefreshCaptcha] = useState(false);
	const [errorCorreo, setErrorCorreo] = useState({ valid: false, msg: '' });
	const [errorNrp, setErrorNrp] = useState({ valid: false, msg: '' });
	const [data, setData] = useState<IActiveRegisterInputs>({
		nrp: '',
		correo: '',
		confirmaCorreo: '',
	});
	const [errorConfirmaCorreo, setErrorConfirmaCorreo] = useState({
		valid: false,
		msg: '',
	});
	const [errorCaptcha, setErrorCaptcha] = useState(false);
	const [alert, setAlert] = useState<IModal>({
		title: '',
		open: false,
		body: '',
		type: '',
	});
	const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

	const {
		execute,
		value: valueRegister,
		status,
	} = useApiRequest<IActiveRegisterParams, IActiveAccountResponse>({
		path: 'autentica/resendActivationEmail',
	});

	const handleValidate = (): void => {
		if (!errorNrp.valid || !data.nrp) {
			return setErrorNrp(nrpValid(data.nrp));
		} else if (!errorCorreo.valid || !data.correo) {
			return setErrorCorreo(emailValid(data.correo));
		} else if (!errorConfirmaCorreo.valid || !data.confirmaCorreo) {
			return setErrorCorreo(compareEmails(data.correo, data.confirmaCorreo));
		} else if (!code || codeInput !== code) {
			setRefreshCaptcha(!refreshCaptcha);
			return setErrorCaptcha(true);
		}
		handleOnSubmit();
	};

	const handleOnSubmit = () => {
		setIsRequestLoading(true);
		execute({ ...data });
	};

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (name === 'correo') {
			const validate = emailValid(value);
			setErrorCorreo(validate);
			handleValidateFormFields(
				data.nrp,
				value,
				data.confirmaCorreo,
				codeInput.length > 0 ? true : false
			);
			if (data.confirmaCorreo !== '') {
				const validate = compareEmails(value, data.confirmaCorreo);
				setErrorConfirmaCorreo(validate);
			}
		} else if (name === 'confirmaCorreo') {
			const validate = compareEmails(data.correo, value);
			setErrorConfirmaCorreo(validate);
			handleValidateFormFields(
				data.nrp,
				data.correo,
				value,
				codeInput.length > 0 ? true : false
			);
		} else if (name === 'nrp') {
			if (value.length > 11) return;
			const validate = nrpValid(value);
			setErrorNrp(validate);
			handleValidateFormFields(
				value,
				data.correo,
				data.confirmaCorreo,
				codeInput.length > 0 ? true : false
			);
		}
		setData({ ...data, [name]: value });
	};

	const handleValidateFormFields = (
		nrp: string,
		correo: string,
		confirmaCorreo: string,
		validCaptcha: boolean
	) => {
		if (
			emailValid(correo).valid &&
			nrpValid(nrp).valid &&
			compareEmails(correo, confirmaCorreo).valid &&
			validCaptcha
		) {
			setValidForm(true);
		} else {
			setValidForm(false);
		}
	};

	const handleValidateRegister = () => {
		if (status === 'success' && valueRegister?.code === RESPONSE_CODES.SUCCESSFUL) {
			setIsRequestLoading(false);
		} else if (
			(status === 'success' || status === 'error') &&
			valueRegister?.code !== RESPONSE_CODES.SUCCESSFUL
		) {
			setIsRequestLoading(false);
			hasRegisterError();
		}
	};

	const hasRegisterError = () => {
		setRefreshCaptcha(!refreshCaptcha);
		setOpenModal(true);
		setAlert({
			open: true,
			body: valueRegister?.message,
			type: 'error',
		});
	};

	useEffect(() => {
		handleValidateRegister();
	}, [valueRegister?.code, status]); // eslint-disable-line react-hooks/exhaustive-deps

	return {
		data,
		alert,
		codeInput,
		refreshCaptcha,
		errorCorreo,
		errorConfirmaCorreo,
		errorNrp,
		errorCaptcha,
		validForm,
		valueRegister,
		status,
		openModal,
		isRequestLoading,
		handleSetData,
		setCode,
		setCodeInput,
		setErrorCaptcha,
		handleValidateFormFields,
		handleValidate,
		setOpenModal,
	};
}

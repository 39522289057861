interface IconInformation {
	svg?: string;
	onClick?: () => void;
	styles?: React.CSSProperties;
}

const IconCustom = (props: IconInformation) => {
	const { svg, onClick, styles } = props;
	return <img src={svg} style={{ ...styles }} alt="icon" onClick={onClick} />;
};

export default IconCustom;

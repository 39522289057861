import { useEffect, useMemo, useState, useContext } from 'react';
import {
	//isCurp,
	isCURPValid,
	//isEmpty,
	isNameValid,
	//isNumber,
	isPasswordValid,
	isPhoneValid,
	isRFC,
	//nameHasSpecialCharacters,
} from '../../../../../../../utils/validators';
import { useApiRequest } from '../../../../../../../hooks/useApiRequest';
import { EMPTY_FIELDS } from '../../../../../constants/empty-field-messages.constanst';
import {
	ICreateUserParams,
	ICreateUserResponse,
} from '../../../../../interfaces/create-user.interface';
import { URLS } from '../../../../../../../constants/urls.constants';
import { INTERNAL_MESSAGES } from '../../../../../../../config/response-codes';
import { CreateAccountContext } from '../../../../../context/CreateAccountContext';
import { useDispatch } from 'react-redux';
import { setLoadingRequest } from '../../../../../../../store/modules/loadingRequest';

interface IUseEBA {
	cancelOption: () => void;
	setActualStep: React.SetStateAction<any>;
	showPassword: boolean;
}

export function useEba({ cancelOption, setActualStep, showPassword }: IUseEBA) {
	const dispatch = useDispatch();
	const { createAccountData, setCreateAccountData } = useContext(CreateAccountContext);

	const [errorEBA, setErrorEBA] = useState({ valid: false, msg: '' });

	const [code, setCode] = useState('');
	const [codeInput, setCodeInput] = useState('');
	const [refreshCaptcha, setRefreshCaptcha] = useState(false);
	const [validCaptcha, setValidCaptcha] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
	const [modalMessage, setModalMessage] = useState<string>('');

	//Conexión del backend

	const {
		execute: createUser,
		value,
		status: statusCreateUser,
	} = useApiRequest<ICreateUserParams, ICreateUserResponse>({
		path: URLS.CREATE_ACCOUNT.REGISTER_EBA,
	});

	const handleOnSubmit = () => {
		dispatch(setLoadingRequest({ loadingRequest: true }));
		createUser({ ...createAccountData });
	};

	useEffect(() => {
		if (statusCreateUser === 'success') {
			dispatch(setLoadingRequest({ loadingRequest: false }));

			switch (value?.code) {
				case '0000':
					setActualStep(2);
					break;
				case '9000':
					setErrorEBA({
						valid: false,
						msg: value?.message!,
					});
					setRefreshCaptcha(!refreshCaptcha);
					break;
				default:
					setModalMessage(value?.message!);
					setShowModal(true);
					setRefreshCaptcha(!refreshCaptcha);
					break;
			}
		} else if (statusCreateUser === 'error') {
			dispatch(setLoadingRequest({ loadingRequest: false }));

			setModalMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			setShowModal(true);
			setRefreshCaptcha(!refreshCaptcha);
		}
	}, [value?.code, statusCreateUser]); // eslint-disable-line react-hooks/exhaustive-deps

	//Fin conexión

	const disabledButton = useMemo((): boolean => {
		if (showPassword === true) {
			if (
				createAccountData.eba!.length > 0 &&
				isNameValid(createAccountData.nombre, 'nombre').valid &&
				isNameValid(createAccountData.apPaterno, 'primerApellido').valid &&
				isRFC(createAccountData.rfc) &&
				isCURPValid(createAccountData.curp).valid &&
				isPhoneValid(createAccountData.telefono).valid &&
				isPasswordValid(createAccountData.psw!) &&
				createAccountData.psw === passwordConfirmation &&
				codeInput.length > 0
			) {
				if (createAccountData.apMaterno!.length > 0)
					return !isNameValid(createAccountData.apMaterno!, 'segundoApellido')
						.valid;
				else return false;
			} else return true;
		} else {
			if (
				createAccountData.eba!.length > 0 &&
				isNameValid(createAccountData.nombre, 'nombre').valid &&
				isNameValid(createAccountData.apPaterno, 'primerApellido').valid &&
				isRFC(createAccountData.rfc) &&
				isCURPValid(createAccountData.curp).valid &&
				isPhoneValid(createAccountData.telefono).valid &&
				codeInput.length > 0
			) {
				if (createAccountData.apMaterno!.length > 0)
					return !isNameValid(createAccountData.apMaterno!, 'segundoApellido')
						.valid;
				else return false;
			} else return true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createAccountData, passwordConfirmation, codeInput]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (name === 'eba') {
			const reg = /^(?!.*\..*\.)[.\d]+$/;
			if (value === '') {
				setErrorEBA({
					valid: false,
					msg: EMPTY_FIELDS.EBA,
				});
			} else {
				if (!value.match(reg)) return;
				setErrorEBA({
					valid: true,
					msg: '',
				});
			}
			setCreateAccountData({ ...createAccountData, [name]: value });
		}
	};

	const handleValidate = () => {
		if (!createAccountData.eba) {
			return setErrorEBA({
				valid: false,
				msg: EMPTY_FIELDS.EBA,
			});
		} else if (!code || codeInput !== code) {
			setRefreshCaptcha(!refreshCaptcha);
			return setValidCaptcha(true);
		}
		handleOnSubmit();
	};

	const cancelEbaValidation = () => {
		cancelOption();
		window.scrollTo(0, 0);
	};

	return {
		errorEBA,
		codeInput,
		code,
		refreshCaptcha,
		validCaptcha,
		disabledButton,
		showModal,
		passwordConfirmation,
		modalMessage,
		setPasswordConfirmation,
		setShowModal,
		handleSetData,
		setCode,
		setCodeInput,
		cancelEbaValidation,
		setRefreshCaptcha,
		handleValidate,
		setValidCaptcha,
	};
}

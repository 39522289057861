// Dependencies
import React, { useEffect } from 'react';

// Components
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Router } from './router';
import store from './store';
import ReactGAV4 from 'react-ga4';

// Material Components
import { ThemeProvider } from '@mui/material/styles';

// Assets
import theme from './config/theme';
import './App.css';

function App(): React.ReactElement {
	useEffect(() => {
		if (process.env.REACT_APP_ENV === 'production') {
			ReactGAV4.initialize(process.env.REACT_APP_KEY_GOOGLE_ANALYTICS_V4 as string);
		}
	}, []);
	return (
		<Provider store={store}>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<Router />
				</ThemeProvider>
			</BrowserRouter>
		</Provider>
	);
}

export default App;

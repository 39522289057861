export const URLS = {
	EFIRMA: {
		VALIDATE: process.env.REACT_APP_EFIRMA,
	},
	CREATE_ACCOUNT: {
		VALIDATION: 'crear-cuenta/validacion',
		REGISTER_EFIRMA: 'crear-cuenta/registro-con-efirma',
		REGISTER_EBA: 'crear-cuenta/registro-con-eba',
	},
};

import { createSlice } from '@reduxjs/toolkit';

export const routeModSlice = createSlice({
	name: 'routeMod',
	initialState: '',
	reducers: {
		setRouteMod: (state, action) => action.payload,
	},
});

export const { setRouteMod } = routeModSlice.actions;

export default routeModSlice.reducer;

import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles/input-file.component.module.css';
import CustomAlert from './alert.component';
import { useMediaQuery, useTheme } from '@mui/material';

interface IInputFile {
	extensionAccepted: string[];
	inputName: string;
	contentStyles?: object;
	name: string;
	id: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onDelete: (name: string) => void;
	error?: boolean;
	helperText?: string;
	styleLabelAlert?: React.CSSProperties;
	resetInput?: boolean;
	messageExcel?: boolean;
}

const InputFile = ({
	extensionAccepted,
	inputName,
	contentStyles,
	name,
	id,
	error,
	styleLabelAlert,
	helperText: propsHelpertext,
	onChange,
	onDelete,
	resetInput,
	messageExcel,
}: IInputFile) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('sm'));
	const [helperText, setHelperText] = useState('');
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [fileName, setFileName] = useState('');

	useEffect(() => {
		setHelperText(propsHelpertext!);
	}, [propsHelpertext]);

	useEffect(() => {
		setFileName('');
		setIsFilePicked(false);
		setHelperText('');
	}, [resetInput]); //eslint-disable-line react-hooks/exhaustive-deps

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const fileList = event.target.files;
		if (!fileList) return;
		const extension = fileList[0].name.split('.').pop();
		if (!extensionAccepted.includes(`.${extension!}`)) {
			messageExcel
				? setHelperText('Adjunta el archivo Excel.')
				: setHelperText(`El archivo debe tener extension ${extensionAccepted}`);
		} else {
			setHelperText('');
			setIsFilePicked(true);
			setFileName(fileList[0].name);
			onChange(event);
		}
	};

	const deleteFile = () => {
		if (isFilePicked) {
			setHelperText(`Seleccionar archivo ${extensionAccepted}`);
			setFileName('');
			setIsFilePicked(false);
		}
	};

	return (
		<div style={{ ...contentStyles }}>
			<div className={styles.container}>
				<div className={styles.inputFileContainer}>
					<span className={styles.inputName}>
						{inputName}
						<span className="mandatory">*</span>
					</span>

					{!isFilePicked ? (
						<span className={styles.messageNoFile}>
							No se eligió ningún archivo
						</span>
					) : (
						<span className={styles.messageFile}>{fileName}</span>
					)}

					{!isFilePicked ? (
						<label className={styles.label}>
							<input
								data-testid={id}
								type="file"
								accept={extensionAccepted.join(', ')}
								id={id}
								name={name}
								onChange={(e) => {
									handleOnChange(e);
								}}
							/>
							<span className={styles.txtElegirArchivo}>
								Elegir archivo
							</span>
						</label>
					) : (
						''
					)}
					{isFilePicked ? (
						<label className={styles.label}>
							<button
								className={styles.txtEliminarArchivo}
								type="button"
								onClick={() => {
									deleteFile();
									onDelete(name);
								}}
							>
								{!matches && <CloseIcon />}
								<span>Eliminar archivo</span>
							</button>
						</label>
					) : (
						''
					)}
				</div>
				<div className={styles.line}></div>
			</div>
			{helperText && (
				<CustomAlert
					severity="error"
					show={true}
					data-testid={`alert-input-${id}`}
					message={helperText}
				/>
			)}
		</div>
	);
};

export default InputFile;

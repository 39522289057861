import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
	show: boolean;
	message: string | null;
	type: 'successful' | 'error' | 'warning' | 'info';
	button: string;
	openModal: boolean;
}

const initialState: ModalState = {
	show: false,
	message: null,
	type: 'error',
	button: '',
	openModal: false,
};

const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		openModal(
			state,
			action: PayloadAction<{
				message: string;
				type: ModalState['type'];
				button: string;
			}>
		) {
			state.show = true;
			state.message = action.payload.message;
			state.type = action.payload.type;
			state.button = action.payload.button;
			state.openModal = true;
		},
		closeModal(state) {
			state.show = false;
			state.message = null;
			state.type = 'error';
			state.button = '';
			state.openModal = false;
		},
	},
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;

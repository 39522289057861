// Dependencies
import React from 'react';
import {
	ContainerCardInformation,
	ContainerCardInformationIcon,
	ContainerCardInformationLabels,
	ContainerInformationText,
	ContainerInformationTitle,
} from '../styled-components/card-information.styled-components';

interface ICardInformation {
	title: string | React.ReactElement;
	children: string | React.ReactElement;
	icon?: React.ReactElement;
	styles?: React.CSSProperties;
	onClick?: () => void;
}

const CardInformation = (props: ICardInformation) => {
	const { title, children, icon } = props;
	return (
		<ContainerCardInformation>
			<ContainerCardInformationIcon>{icon}</ContainerCardInformationIcon>
			<ContainerCardInformationLabels>
				<ContainerInformationTitle>{title}</ContainerInformationTitle>
				<ContainerInformationText>{children}</ContainerInformationText>
			</ContainerCardInformationLabels>
		</ContainerCardInformation>
	);
};

export default CardInformation;

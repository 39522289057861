import style from '../styles/home-styles.component.module.css';
import img1 from '../../../assets/imgs/home-add-1.png';
import img2 from '../../../assets/imgs/home-add-2.png';
import img3 from '../../../assets/imgs/home-add-3.png';
import { Link } from 'react-router-dom';

const FourthComponent = () => {
	const bannerServices = [
		{
			name: 'Beneficios para tus trabajadores',
			name2: 'Si eres una Empresa de Diez Plus, tus trabajadores que formalicen su primer crédito obtendrán un beneficio económico.',
			id: 1,
			to: '/consultas/empresas-de-diez',
			img: img1,
		},
		{
			name: 'Pagos en exceso ',
			name2: 'Consulta los avisos de crédito de tus trabajadores y evita hacer retenciones o pagos en exceso.',
			id: 2,
			to: '/mis-tramites/devoluciones-sua',
			img: img2,
		},
		{
			name: ' Constancia de situación fiscal ',
			name2: 'Obtén el documento que muestra el cumplimiento de tus obligaciones fiscales con el Infonavit.',
			id: 3,
			to: '/mis-tramites/constancia-de-situacion-fiscal',
			img: img3,
		},
	];
	return (
		<div className={style.fourthComponent}>
			{bannerServices.map((banner) => {
				return (
					<div className={style.servicesContainer} key={banner.id}>
						<img
							src={banner.img}
							className={style.servicesImg}
							alt="infonavit"
						/>
						<Link to={banner.to} className={style.link}>
							<p className={style.serviceText}>{banner.name}</p>

							<p className={`${style.serviceText} ${style.serviceText2}`}>
								{banner.name2}
							</p>
						</Link>
					</div>
				);
			})}
		</div>
	);
};

export default FourthComponent;

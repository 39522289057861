//components
import { CircularProgress } from '@mui/material';
import { Button, Input, Modal, PasswordComponent, Title } from '../../components';

//hooks
import { useForm } from './hooks/useForm';

//styles
import style from './styles/profile-change-password.module.css';
interface IProfileChangePasswordPage {
	setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
	isLogin?: boolean;
	data?: { correo: string; nrp: string; password: string };
	closeModal?: (message: string) => void;
}

export function ProfileChangePasswordPage({
	setSelectedTab,
	isLogin,
	data,
	closeModal,
}: IProfileChangePasswordPage) {
	const {
		formData,
		error,
		isDisabled,
		onChange,
		updatePassword,
		modal,
		setLoader,
		loader,
	} = useForm({
		isLogin,
		data,
	});
	const { consignaAnterior, consignaNueva, confirmP } = formData;
	const { errorPassword, errorNewPassword, errorConfirmPassword } = error;

	const callback = () => {
		if (modal.type === 'successful') {
			isLogin ? closeModal?.('login') : setSelectedTab(0);
		} else {
			if (isLogin) closeModal?.('password');
		}
	};

	return (
		<form onSubmit={updatePassword} className={style.form}>
			<div className={style.info}>
				<Title>Cambiar contraseña</Title>
				<Input
					id="consignaAnterior"
					name="consignaAnterior"
					type="password"
					value={consignaAnterior}
					onChange={onChange}
					onBlur={onChange}
					label={'Contraseña actual'}
					error={errorPassword}
					autoComplete="on"
					notCopy
					notPaste
					required
				/>
				<Input
					id="consignaNueva"
					name="consignaNueva"
					type="password"
					value={consignaNueva}
					onChange={onChange}
					onBlur={onChange}
					label={'Nueva contraseña'}
					error={errorNewPassword}
					autoComplete="on"
					notCopy
					notPaste
					required
				/>
				<PasswordComponent password={consignaNueva} />
				<Input
					id="confirmP"
					name="confirmP"
					type="password"
					value={confirmP}
					onChange={onChange}
					onBlur={onChange}
					label={'Confirma tu contraseña'}
					error={errorConfirmPassword}
					autoComplete="on"
					notCopy
					notPaste
					required
				/>
				<p className={style.datos}>
					<span>*</span>
					Datos obligatorios
				</p>
			</div>
			<div className={style.buttonContent}>
				<Button
					disabled={isDisabled}
					label={'Cambiar contraseña'}
					variant={'solid'}
				/>
			</div>
			{isLogin && (
				<Modal openModal={loader} labelButton="Aceptar" setOpenModal={setLoader}>
					<>
						<CircularProgress />
						<p>Cargando...</p>
					</>
				</Modal>
			)}
			<Modal
				openModal={modal.openModal}
				labelButton={modal.button}
				setOpenModal={modal.closeModal}
				callback={callback}
				type={modal.type}
				closeButton
			>
				<p>{modal.message}</p>
			</Modal>
		</form>
	);
}

import { TextareaHTMLAttributes } from 'react';
import { Label } from './label.component';
import { AlertInput } from './alert-input.component';

import style from './styles/textarea-styles.component.module.css';

interface ITextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	error?: { valid: boolean; message: string };
	label: string;
	name: string;
}

export default function Textarea({ label, error, name, ...rest }: ITextareaProps) {
	const styleTextarea = rest.value === '' ? style.empty : style.notEmpty;

	return (
		<div className={style.content}>
			<Label label={label} name={name} required={rest.required} />
			<textarea
				className={`${style.textarea} ${styleTextarea}`}
				name={name}
				{...rest}
			/>
			{error && (
				<AlertInput name={name} valid={error.valid} message={error.message} />
			)}
		</div>
	);
}

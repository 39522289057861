export const ALERT_COLORS = {
	WARNING_BACKGROUND: '#FAE295',
	INFO_BACKGROUND: '#EDF0FF',
	ERROR_BACKGROUND: '#FFE2E5',
	SUCCESS_BACKGROUND: '#E9FAE5',
	WARNING_TEXT: '#E35700',
	INFO_TEXT: '#293990',
	ERROR_TEXT: '#DD052B',
	SUCCESS_TEXT: '#3ABE21',
};
export const ALERT_SEVERITY_TYPES = {
	WARNING: 'warning',
	INFO: 'info',
	ERROR: 'error',
	SUCCESS: 'success',
};

import { useState } from 'react';
import useCustomScript from '../../../components/hooks/useCustomScript';
import { defaultEfirmaData, IDatosEfirma } from '../../../interfaces/efirma.interface';

export function useRemovalOfRepresentative() {
	useCustomScript('/efirma/efirma-validacion.ofu.min.js');
	useCustomScript('/efirma/firma-navegador.ofu.min.js');
	useCustomScript('/js/jquery.min.js');
	useCustomScript('/js/jsrsasign-latest-all-min.js');

	const [page, setPage] = useState<'form' | 'confirmation'>('form');
	const [pdfB64, setPdfB64] = useState<string>('');
	const [nrp, setNrp] = useState<string>('');
	const [folio, setFolio] = useState<string>('');
	const [cadenaOriginal, setCadenaOriginal] = useState<string>('');
	const [eFirmaData, setEFirmaData] = useState<IDatosEfirma>(defaultEfirmaData);

	return {
		page,
		pdfB64,
		nrp,
		folio,
		eFirmaData,
		cadenaOriginal,
		setPage,
		setPdfB64,
		setNrp,
		setFolio,
		setEFirmaData,
		setCadenaOriginal,
	};
}

export const AUTH_CODES: { [key: string]: string } = {
	'0001': 'No tenemos registrado el Número de Registro Patronal (NRP) que escribiste. Intenta de nuevo.',
	'0002': 'El correo electrónico que escribiste ya está registrado. Intenta con otro correo. Si olvidaste tu contraseña haz clic',
	'0003': 'Ya existe una cuenta con los datos que escribiste, sin	embargo está inactiva. Actívala',
	'0004': 'El representante legal de esta empresa ya ha sido registrado. Por favor solicita que te dé de alta como usuario adicional en la sección “Administración de usuarios” dentro del Portal Empresarial.',
	'0007': 'OK',
};

export const BIMESTER_STRINGS: { [key: string]: string } = {
	'01': 'Primer bimestre de',
	'02': 'Segundo bimestre de',
	'03': 'Tercer bimestre de',
	'04': 'Cuarto bimestre de',
	'05': 'Quinto bimestre de',
	'06': 'Sexto bimestre de',
};

import React from 'react';
import { IDatosEfirma } from '../interfaces/efirma.interface';
import InputFile from './input-file.component';
import Input from './input.component';

interface IModuloEfirma {
	efirmaData: IDatosEfirma;
	setEfirmaData: React.SetStateAction<any>;
	errorPassEfirma: { valid: boolean; msg: string };
	errorKeyFile: { valid: boolean; msg: string };
	errorCerFile?: { valid: boolean; msg: string };
	styles?: React.CSSProperties;
	resetData?: boolean;
}

const ModuloEfirma = ({
	efirmaData,
	setEfirmaData,
	errorPassEfirma,
	errorKeyFile,
	errorCerFile = { valid: true, msg: '' },
	styles,
	resetData,
}: IModuloEfirma) => {
	//const [error, setError] = useState({ valid: false, msg: '' });

	const handlePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (event.target.value.length > 100) return;
		const { value } = event.target;
		if (value === '') {
			//setError({ valid: false, msg: 'Escribe la contraseña de la e.firma.' });
			errorPassEfirma.valid = false;
			errorPassEfirma.msg = 'Escribe la contraseña de la e.firma.';
		} else {
			//setError({ valid: true, msg: '' });
			errorPassEfirma.valid = true;
			errorPassEfirma.msg = '';
		}
		setEfirmaData((prev: IDatosEfirma) => ({ ...prev, psw: value }));
	};

	const handleCerFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
		errorCerFile.msg = '';
		const fileList = event.target.files;
		if (!fileList) return;

		const readerFile = new FileReader();
		readerFile.onload = function (event) {
			setEfirmaData((prev: any) => ({ ...prev, cerBinary: event.target?.result }));
			const b64 = window.btoa(
				new Uint8Array(event.target?.result as ArrayBuffer).reduce(
					(data, byte) => data + String.fromCharCode(byte),
					''
				)
			);
			setEfirmaData((prev: any) => ({ ...prev, cerB64: b64 }));
		};
		readerFile.readAsArrayBuffer(fileList[0]);
	};

	const handleKeyFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
		errorKeyFile.msg = '';
		const fileList = event.target.files;
		if (!fileList) return;
		const readerFile = new FileReader();
		readerFile.onload = function (event) {
			setEfirmaData((prev: any) => ({ ...prev, keyBinary: event.target?.result }));
		};
		readerFile.readAsArrayBuffer(fileList[0]);
	};

	const handleDeleteKeyFile = () => {
		setEfirmaData((prev: any) => ({ ...prev, keyBinary: '' }));
		errorKeyFile.msg = '';
	};

	const handleDeleteCerFile = () => {
		setEfirmaData((prev: any) => ({ ...prev, cerB64: '' }));
		errorCerFile.msg = '';
	};

	return (
		<div
			style={
				styles
					? styles
					: { display: 'flex', flexDirection: 'column', gap: '24px' }
			}
		>
			<InputFile
				extensionAccepted={['.cer']}
				inputName="Certificado (.cer)"
				name="cerFile"
				id="cerFile"
				onChange={handleCerFile}
				onDelete={handleDeleteCerFile}
				error={errorCerFile.valid}
				helperText={errorCerFile.msg}
				resetInput={resetData}
			/>
			<InputFile
				extensionAccepted={['.key']}
				inputName="Clave privada (.key)"
				name="keyFile"
				id="keyFile"
				onChange={handleKeyFile}
				onDelete={handleDeleteKeyFile}
				error={errorKeyFile.valid}
				helperText={errorKeyFile.msg}
				resetInput={resetData}
			/>
			<Input
				data-testid="plainPassword"
				name="plainPassword"
				label="Contraseña de la e.firma"
				type="password"
				onChange={handlePassword}
				value={efirmaData.psw}
				error={errorPassEfirma}
				autoComplete="on"
				maxLength={100}
				required
			/>
		</div>
	);
};

export default ModuloEfirma;

// import styled from 'styled-components';
import { styled } from '@mui/material/styles';

export const ContainerCardsInformation = styled('div')(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center',
	padding: '40px 100px',
	gap: '24px',
	backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
		'--color-5-grey'
	),
	[theme.breakpoints.down('lg')]: {
		padding: '20px 50px',
	},
	[theme.breakpoints.down('sm')]: {
		padding: '20px 10px',
	},
}));

export const ContainerCardInformation = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	width: 398,
	padding: '24px',
	gap: '16px',
	borderRadius: '16px',
	backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
		'--color-9-grey'
	),
	boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 8px',
	[theme.breakpoints.down('lg')]: {
		marginTop: 20,
	},
	[theme.breakpoints.down('sm')]: {
		marginTop: 15,
	},
}));

export const ContainerCardInformationIcon = styled('div')(() => ({
	display: 'flex',
	height: '100%',
	width: 'auto',
	flexDirection: 'column',
}));

export const ContainerCardInformationLabels = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
}));
export const ContainerInformationTitle = styled('label')(() => ({
	fontSize: getComputedStyle(document.documentElement).getPropertyValue(
		'--txt-size-Default'
	),
	marginBottom: 8,
	lineHeight: '22px',
	fontWeight: 500,
	color: '#333',
}));
export const ContainerInformationText = styled('label')(() => ({
	fontSize: getComputedStyle(document.documentElement).getPropertyValue(
		'--txt-size-XSmall'
	),
	fontWeight: 400,
	lineHeight: '18px',
	color: '#7F7F7F',
}));

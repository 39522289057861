export const getRfcByEfirma = (rfc: any) => {
	if ((rfc !== null || rfc !== undefined) && typeof rfc === 'string') {
		if (rfc.includes('/')) {
			return rfc.split('/')[0].trim();
		} else {
			return rfc;
		}
	} else {
		return rfc;
	}
};

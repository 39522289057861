import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

//hooks
import { useApiRequest } from '../../../hooks/useApiRequest';
import { useModal } from './useModal';

//interfaces, constants and utils
import { IError, IForm, IResponsePass, IPassword } from '../interfaces/profile.interface';
import { initialValues, dataError } from '../constants/password.constants';
import { getProfile } from '../../../utils/getProfile';
import {
	isPasswordValid,
	isConfirmPasswordValid,
	isPasswordOldValid,
	validateLetrasPassword,
	isPasswordOldValidLogin,
} from '../../../utils/validators';
import { RESPONSE_CODES } from '../../../config/response-codes';
import { setLoading } from '../../../store/modules/loadingTwo';

interface Props {
	isLogin?: boolean;
	data?: { correo: string; nrp: string; password: string } | undefined;
}
export function useForm({ isLogin, data }: Props) {
	const [formData, setFormData] = useState<IForm>(initialValues);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const [loader, setLoader] = useState<boolean>(false);

	const [errorPassword, setErrorPassword] = useState<IError>(dataError);
	const [errorNewPassword, setErrorNewPassword] = useState<IError>(dataError);
	const [errorConfirmPassword, setErrorConfirmPassword] = useState<IError>(dataError);
	const { modal, openModal } = useModal();
	const dispatch = useDispatch();
	const { execute, value, status } = useApiRequest({
		path: 'mi-perfil/cambiar-contrasenia',
	});
	const {
		execute: executeChange,
		value: valueChange,
		status: statusChange,
	} = useApiRequest<IPassword, IResponsePass>({
		path: 'autentica/changePassword',
		baseURL: process.env.REACT_APP_API_URL,
	});

	useEffect(() => {
		if (
			statusChange === 'success' &&
			valueChange?.code === RESPONSE_CODES.SUCCESSFUL
		) {
			setLoader(false);
			openModal({
				type: 'successful',
				button: 'Finalizar',
				message: valueChange.message,
			});
		} else if (
			(statusChange === 'success' || statusChange === 'error') &&
			valueChange?.code !== RESPONSE_CODES.SUCCESSFUL
		) {
			setLoader(false);
			openModal({
				type: 'error',
				button: 'Cerrar',
				message: valueChange?.message,
			});
		}
	}, [valueChange?.code, statusChange]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (value?.code !== undefined) {
			dispatch(setLoading({ loading: false }));
			if (value?.code === '0001') {
				openModal({
					type: 'successful',
					button: 'Finalizar',
					message: value.message,
				});
			} else {
				openModal({
					type: 'error',
					button: 'Cerrar',
					message: value.message,
				});
			}
		}
	}, [value?.code, status]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		errorPassword.valid && errorNewPassword.valid && errorConfirmPassword.valid
			? setIsDisabled(false)
			: setIsDisabled(true);
	}, [errorNewPassword.valid, errorConfirmPassword.valid, errorPassword.valid]);

	const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		if (!validateLetrasPassword(target.value)) return;
		target.value = target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

		if (target.name === 'consignaAnterior') {
			if (isLogin) {
				setErrorPassword(isPasswordOldValidLogin(target.value));
			} else {
				setErrorPassword(isPasswordOldValid(target.value));
			}
		}
		if (target.name === 'consignaNueva') {
			if (
				target.value.includes('<') ||
				target.value.includes('>') ||
				target.value.includes('\\') ||
				target.value.includes('&') ||
				target.value.includes('|')
			) {
				return;
			}
			setErrorNewPassword(isPasswordValid(target.value));
			if (target.value !== '' && formData.confirmP !== '') {
				setErrorConfirmPassword(
					isConfirmPasswordValid(formData.confirmP, target.value)
				);
			}
		}
		if (target.name === 'confirmP') {
			setErrorConfirmPassword(
				isConfirmPasswordValid(target.value, formData.consignaNueva)
			);
		}
		setFormData({
			...formData,
			[target.name]: target.value,
		});
	};
	const updatePassword = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (errorPassword.valid && errorNewPassword.valid && errorConfirmPassword.valid) {
			if (isLogin && data) {
				setLoader(true);
				executeChange({
					nrp: data.nrp,
					correo: data.correo,
					consignaAnterior: formData.consignaAnterior,
					consignaNueva: formData.consignaNueva,
				});
			} else {
				if (getProfile()) {
					dispatch(setLoading({ loading: true }));
					execute({
						correo: getProfile().contacto.correo,
						consignaAnterior: formData.consignaAnterior,
						consignaNueva: formData.consignaNueva,
					});
				}
			}
		}
	};

	return {
		formData,
		error: { errorPassword, errorNewPassword, errorConfirmPassword },
		isDisabled,
		updatePassword,
		onChange,
		modal,
		setLoader,
		loader,
	};
}

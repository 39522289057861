import { useContext } from 'react';
import { PasswordComponent, TextInput } from '../../../../../../components';
import { CreateAccountContext } from '../../../../context/CreateAccountContext';
import { useGeneralDataForm } from './hooks/useGeneralDataForm';

import styles from './styles/general-data-form.styles.component.module.css';

export interface IGeneralDataForm {
	passwordConfirmation: string;
	setPasswordConfirmation: React.Dispatch<React.SetStateAction<string>>;
}

const GeneralDataForm = (props: IGeneralDataForm) => {
	const { createAccountData, showPassword } = useContext(CreateAccountContext);

	const {
		errorNombre,
		errorPrimerApellido,
		errorSegundoApellido,
		errorRFC,
		errorCURP,
		errorTelefono,
		errorContrasenia,
		errorConfirmacionContrasenia,
		handleSetData,
	} = useGeneralDataForm(props);

	return (
		<div className={styles.datosContainer}>
			<TextInput
				id="nombre"
				name="nombre"
				placeholder="p.e. Juan José"
				labelStyles={{ fontSize: 16 }}
				value={createAccountData.nombre}
				onChange={handleSetData}
				onBlur={handleSetData}
				labelComponent={
					<p className={styles.labelContainerInput}>
						Nombre<span className="mandatory">*</span>
					</p>
				}
				error={!errorNombre.valid}
				helperText={!errorNombre.valid ? errorNombre.msg : ''}
				maxLength={50}
			/>
			<div className={styles.apellidosContainer}>
				<TextInput
					id="apPaterno"
					name="apPaterno"
					placeholder="p.e. Pérez"
					labelStyles={{ fontSize: 16 }}
					value={createAccountData.apPaterno}
					onChange={handleSetData}
					onBlur={handleSetData}
					labelComponent={
						<p className={styles.labelContainerInput}>
							Primer apellido<span className="mandatory">*</span>
						</p>
					}
					error={!errorPrimerApellido.valid}
					helperText={!errorPrimerApellido.valid ? errorPrimerApellido.msg : ''}
					maxLength={50}
				/>
				<TextInput
					id="apMaterno"
					name="apMaterno"
					placeholder="p.e. López"
					labelStyles={{ fontSize: 16 }}
					value={createAccountData.apMaterno!}
					onChange={handleSetData}
					labelComponent={
						<p className={styles.labelContainerInput}>Segundo apellido</p>
					}
					error={!errorSegundoApellido.valid}
					helperText={
						!errorSegundoApellido.valid ? errorSegundoApellido.msg : ''
					}
					maxLength={50}
				/>
			</div>
			<TextInput
				id="rfc"
				name="rfc"
				placeholder="p.e. BADD211200XYZ"
				labelStyles={{ fontSize: 16 }}
				value={createAccountData.rfc}
				onChange={handleSetData}
				onBlur={handleSetData}
				labelComponent={
					<p className={styles.labelContainerInput}>
						RFC con homoclave<span className="mandatory">*</span>
					</p>
				}
				error={!errorRFC.valid}
				helperText={!errorRFC.valid ? errorRFC.msg : ''}
				maxLength={13}
			/>
			<TextInput
				id="curp"
				name="curp"
				placeholder="p.e. BADD211200XYZ"
				labelStyles={{ fontSize: 16 }}
				value={createAccountData.curp}
				onChange={handleSetData}
				onBlur={handleSetData}
				labelComponent={
					<p className={styles.labelContainerInput}>
						CURP<span className="mandatory">*</span>
					</p>
				}
				error={!errorCURP.valid}
				helperText={!errorCURP.valid ? errorCURP.msg : ''}
				maxLength={18}
			/>
			<TextInput
				id="telefono"
				name="telefono"
				placeholder="p.e. 5512345678"
				labelStyles={{ fontSize: 16 }}
				value={createAccountData.telefono}
				onChange={handleSetData}
				onBlur={handleSetData}
				labelComponent={
					<p className={styles.labelContainerInput}>
						Teléfono<span className="mandatory">*</span>
					</p>
				}
				maxLength={10}
				error={!errorTelefono.valid}
				helperText={!errorTelefono.valid ? errorTelefono.msg : ''}
			/>
			{showPassword === true ? (
				<>
					<TextInput
						type="password"
						id="psw"
						name="psw"
						placeholder="*************"
						labelStyles={{ fontSize: 16 }}
						value={createAccountData.psw!}
						onChange={handleSetData}
						onBlur={handleSetData}
						labelComponent={
							<p className={styles.labelContainerInput}>
								Contraseña<span className="mandatory">*</span>
							</p>
						}
						error={!errorContrasenia.valid}
						helperText={!errorContrasenia.valid ? errorContrasenia.msg : ''}
						maxLength={12}
					/>
					<PasswordComponent password={createAccountData.psw!} />
					<TextInput
						type="password"
						id="passwordConfirmation"
						name="passwordConfirmation"
						placeholder="*************"
						labelStyles={{ fontSize: 16 }}
						value={props.passwordConfirmation}
						onChange={handleSetData}
						onBlur={handleSetData}
						labelComponent={
							<p className={styles.labelContainerInput}>
								Confirmar contraseña
								<span className="mandatory">*</span>
							</p>
						}
						error={!errorConfirmacionContrasenia.valid}
						helperText={
							!errorConfirmacionContrasenia.valid
								? errorConfirmacionContrasenia.msg
								: ''
						}
						noPaste={true}
						maxLength={12}
					/>
				</>
			) : null}
		</div>
	);
};

export default GeneralDataForm;

import style from '../styles/home-styles.component.module.css';
import { getProfile } from '../../../utils/getProfile';

const FirstComponent = () => {
	const profile = getProfile();
	const date = profile?.contacto?.fechaUltimoAcceso.substr(0, 10);

	return (
		<div className={style.firstContainer}>
			<b style={{ fontSize: '26px' }}>Bienvenido a tu Portal Empresarial.</b>
			<p style={{ fontSize: '18px' }}>
				Fecha de último acceso: <b>{date || ''}</b>
			</p>
		</div>
	);
};

export default FirstComponent;

import style from './styles/label-styles.component.module.css';

interface LabelProps {
	label?: string;
	required?: boolean;
	name: string;
}

export function Label({ name, label, required }: LabelProps) {
	return (
		<>
			{label && (
				<label className={style.label} htmlFor={name}>
					{label}
					{required && <span>*</span>}
				</label>
			)}
		</>
	);
}

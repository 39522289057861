import { Component, ErrorInfo, ReactNode } from 'react';
import styles from './error-bundary.module.css';
import failIcon from '../assets/svg/failModal.svg';
import { INTERNAL_MESSAGES } from '../config/response-codes';
import CustomButton from '../components/button.component';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
	};

	public static getDerivedStateFromError(_: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		//console.error('Uncaught error:', error, errorInfo);
	}

	private onClick() {
		window.location.href = '/inicio';
	}

	public render() {
		if (this.state.hasError) {
			return (
				<div className={styles.container}>
					<div className={styles.boxFirst}>
						<img src={failIcon} alt="imagen" style={{ marginTop: '2px' }} />
						<div className={styles.body}>
							<p>{INTERNAL_MESSAGES.ERROR_MESSAGE}</p>
							<div className={styles.containerButtonModal}>
								<CustomButton
									onClick={() => this.onClick()}
									styles={{ width: 211 }}
									variant="solid"
									label="Cerrar"
								/>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;

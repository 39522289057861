// Dependencies
import { Backdrop } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

//
import gifLoading from '../assets/gifts/logo-min.gif';
import { themBackdrop } from '../config/theme';
import styles from './styles/loading-styles.component.module.css';

const LoadingRouter = () => {
	return (
		<ThemeProvider theme={themBackdrop}>
			<Backdrop open={true}>
				<div className={styles.containerLoading}>
					<img
						src={gifLoading}
						alt="loading-infonavit-gift"
						className={styles.imgGift}
					/>
					<p className={styles.textLoading}>Cargando...</p>
				</div>
			</Backdrop>
		</ThemeProvider>
	);
};

export default LoadingRouter;

import style from './styles/hero-styles.component.module.css';

interface IHeaderProps {
	title: string;
	backgroundImageUrl: string;
}

export default function Hero({ title, backgroundImageUrl }: IHeaderProps) {
	const containerStyle = {
		backgroundImage: `url(${backgroundImageUrl})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'left top',
		backgroundSize: 'cover',
	};

	const getStyle = () => {
		return containerStyle;
	};

	return (
		<div className={style.header} style={getStyle()}>
			<h1>{title}</h1>
		</div>
	);
}

export const PROFILE_CODES: { [key: string]: string } = {
	'0001': 'Tu contraseña actual es incorrecta',
	'0002': 'El usuario no existe',
	'0003': 'Contraseña cambiada correctamente',
};

export const defaultModal = {
	show: false,
	message: '',
	type: '',
	button: '',
	openModal: false,
};

export const dataError = {
	valid: false,
	msg: '',
};

export const initData = {
	NRP: '',
	RFC: '',
	razonSocial: '',
	calle: '',
	colonia: '',
	municipio: '',
	estado: '',
	codigoPostal: '',
	nombre: '',
	puesto: '',
	correo: '',
};

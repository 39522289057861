import FirstComponent from './components/FirstComponent';
import FourthComponent from './components/FourthComponent';
import SecondComponent from './components/SecondComponent';
import ThirdComponent from './components/ThirdComponent';
import style from './styles/home-styles.component.module.css';
import { useLayout } from '../../hooks/useLayout';

const HomePage = () => {
	useLayout({
		title: '',
		breadcrumbs: [],
		background: '',
	});

	return (
		<div className={style.container}>
			<FirstComponent />
			<SecondComponent />
			<ThirdComponent />
			<FourthComponent />
		</div>
	);
};

export default HomePage;

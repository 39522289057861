import { createSlice } from '@reduxjs/toolkit';

export const questionsSlice = createSlice({
	name: 'questions',
	initialState: false,
	reducers: {
		setShowQuestion: (state, action) => action.payload,
	},
});

export const { setShowQuestion } = questionsSlice.actions;

export default questionsSlice.reducer;

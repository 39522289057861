import facebook from '../../assets/svg/facebook.svg';
import linkedin from '../../assets/svg/linkedin.svg';
import youtube from '../../assets/svg/youtube.svg';
import spotify from '../../assets/svg/spotify.svg';
import twitter from '../../assets/logos/twitter.png';

type SocialMedia = {
	id: number;
	icon: string;
	title: string;
	url: string | undefined;
};

export const SOCIALMEDIA: SocialMedia[] = [
	{
		id: 1,
		icon: facebook,
		title: 'Facebook',
		url: process.env.REACT_APP_URL_FOOTER_FACE,
	},
	{
		id: 2,
		icon: linkedin,
		title: 'Linkedin',
		url: process.env.REACT_APP_URL_FOOTER_LINKEDIN,
	},
	{
		id: 3,
		icon: youtube,
		title: 'Youtube',
		url: process.env.REACT_APP_URL_FOOTER_YOUTUBE,
	},
	{
		id: 4,
		icon: spotify,
		title: 'Spotify',
		url: process.env.REACT_APP_URL_FOOTER_SPOTIFY,
	},
	{
		id: 5,
		icon: twitter,
		title: 'Twitter',
		url: process.env.REACT_APP_URL_FOOTER_TWITTER,
	},
];

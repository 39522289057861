const isBase64Pdf = (base64String: any) => {
	const decodedData = atob(base64String);
	const bytes = new Uint8Array(decodedData.length);

	for (let i = 0; i < decodedData.length; i++) {
		bytes[i] = decodedData.charCodeAt(i);
	}
	// Verificar la firma  del PDF
	const pdfSignature = bytes.subarray(0, 4); // Los primeros 4 bytes del archivo

	// Firma del formato PDF: %PDF
	const pdfMagicNumber = [0x25, 0x50, 0x44, 0x46];

	// Compara la firma
	for (let i = 0; i < pdfMagicNumber.length; i++) {
		if (pdfSignature[i] !== pdfMagicNumber[i]) {
			return false; // No coincide con la firma del PDF
		}
	}

	return true; // Es un archivo PDF
};

export const generatePDF = (
	base64: string,
	extension: string,
	nombre: string,
	open = false
) => {
	if (extension !== 'docx') {
		if (!isBase64Pdf(base64)) {
			throw new Error('El archivo no es un PDF válido.');
		}
	}

	const byteCharacters = atob(base64);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob = new File([byteArray], 'file.pdf', {
		type: 'application/pdf',
	});
	const url = window.URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', nombre + '.' + extension);
	link.click();
	if (open) {
		window.open(url, '_blank');
		window.URL.revokeObjectURL(url);
	}
};

export const RESPONSE_CODES = {
	SUCCESSFUL: '0000',
};
export const INTERNAL_CODES = {
	// Basic error codes
	SUCCESSFUL: '0000',
	NO_CODE: '5000',
	// Validations error codes
	VALIDATION_ERROR_CODE: '1000',
	INFONAVIT_VALIDATION_ERROR_CODE: '1001',
	TOKEN_VALIDATION_ERROR_CODE: 'JWT00',
	// Internal error codes
	UNKNOWN_ERROR_CODE: '9000',
	ERROR_CODE: '9001',
};
export const INTERNAL_MESSAGES = {
	SUCCESSFUL: 'Consulta Exitosa',
	UNKNOWN_ERROR_MESSAGE: 'Error desconocido',
	ERROR_MESSAGE:
		'Por el momento los servicios no se encuentran disponibles. Intenta más tarde.',
	ENCRYPT_WORDS_OBLIGATORY: 'Palabra inicial y final del cifrado son obligatorios.',
	ENCRYPT_ERROR: 'Error al encriptar la cadena.',
};

export const EFIRMA_MESSAGES = {
	INCORRECT_PSW: 'La contraseña de la llave privada es incorrecta.',
	FILES_DONT_MATCH: 'El certificado y la llave privada no corresponden.',
	NOT_EFIRMA_FILES: 'Los archivos no son de tipo e.firma.',
	NOT_VALID_CERTIFICATE: 'El certificado no está vigente, verficar con el SAT.',
	NOT_EMITED_BY_SAT: 'El certificado no fue emitido por el SAT.',
	RFC_NOT_VALID_WITH_NRP:
		'El RFC de la e.firma no corresponde al NRP con el que estás ingresando al servicio, intenta nuevamente.',
};

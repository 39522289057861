import { createSlice } from '@reduxjs/toolkit';

export const rutaCSFSlice = createSlice({
	name: 'rutaCSF',
	initialState: '',
	reducers: {
		setRutaCSF: (state, action) => action.payload,
	},
});

export const { setRutaCSF } = rutaCSFSlice.actions;

export default rutaCSFSlice.reducer;

import { useDispatch, useSelector } from 'react-redux';
import { openModal, closeModal } from './modal-error.slice';
import { AppDispatch, RootState } from '../../index';
import { INTERNAL_MESSAGES } from '../../../config/response-codes';

const useModalErrorStore = () => {
	const dispatch = useDispatch<AppDispatch>();
	const modalError = useSelector((state: RootState) => state.modalError);

	const openErrorModal = (message?: string) => {
		dispatch(
			openModal({
				message: message ? message : INTERNAL_MESSAGES.ERROR_MESSAGE,
				type: 'error',
				button: 'Aceptar',
			})
		);
	};

	const closeModalHandler = () => {
		dispatch(closeModal());
	};

	return { openErrorModal, closeModalHandler, modalError };
};

export default useModalErrorStore;

import { createSlice } from '@reduxjs/toolkit';

export const nameEfirmaSlice = createSlice({
	name: 'nameEfirma',
	initialState: '',
	reducers: {
		setNameEfirma: (state, action) => action.payload,
	},
});

export const { setNameEfirma } = nameEfirmaSlice.actions;

export default nameEfirmaSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

export const loadingTwoSlice = createSlice({
	name: 'loadingTwo',
	initialState: { loading: false },
	reducers: {
		setLoading: (state, action) => ({ ...state, ...action.payload }),
		stopLoading: () => ({ loading: false }),
	},
});

export const { setLoading, stopLoading } = loadingTwoSlice.actions;

export default loadingTwoSlice.reducer;

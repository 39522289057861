import { Label } from './label.component';
import { AlertInput } from './alert-input.component';

//hooks
import { useAutocomplete } from './hooks/useAutocomplete';
import { IUseAutocompleteProps } from '../interfaces/autocomplete.interface';

//styles
import style from './styles/autocomplete-styles.component.module.css';

export default function Autocomplete({
	name,
	label,
	options,
	searchValue,
	value,
	onChange,
	...rest
}: IUseAutocompleteProps) {
	const { error, afterSelected, required, validate } = rest;
	const { handleClick, groupedOptions, getInputProps, isFocused, ref } =
		useAutocomplete({
			afterSelected,
			options,
			validate,
			searchValue,
			value,
			onChange,
		});
	return (
		<div className={style.content}>
			<Label label={label} name={name} required={required} />
			<div className={style.container} ref={ref}>
				<input
					className={`${style.input} ${value ? style.notEmpty : style.empty}`}
					name={name}
					autoComplete="off"
					{...getInputProps()}
				/>
				{isFocused && groupedOptions.length > 0 && (
					<div className={style.listbox}>
						{groupedOptions.map((option) => (
							<li onClick={handleClick(option)} key={option.label}>
								{option.label}
							</li>
						))}
					</div>
				)}
			</div>
			{error && <AlertInput name={name} valid={error.valid} message={error.msg} />}
		</div>
	);
}

import React from 'react';
import { useDispatch } from 'react-redux';

import { INTERNAL_MESSAGES, EFIRMA_MESSAGES } from '../config/response-codes';
import { IResponseEfirmaValidation } from '../interfaces/efirma.interface';
import { setLoading } from '../store/modules/loading';
import { setLoadingRequest } from '../store/modules/loadingRequest';
import { getRfcByEfirma } from '../utils/getRfcByEfirma';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

interface IEfirmaValidationFunction {
	setEfirmaValidationResponseData: React.Dispatch<
		React.SetStateAction<IResponseEfirmaValidation>
	>;
	setModalMessage: React.Dispatch<React.SetStateAction<string>>;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	formHasCapcha: boolean;
	setRefreshCaptcha?: React.Dispatch<React.SetStateAction<boolean>>;
	refreshCaptcha?: boolean;
	setErrorPassEfirma: React.Dispatch<
		React.SetStateAction<{
			valid: boolean;
			msg: string;
		}>
	>;
	setErrorKeyFile: React.Dispatch<
		React.SetStateAction<{
			valid: boolean;
			msg: string;
		}>
	>;
	setErrorCerFile?: React.Dispatch<
		React.SetStateAction<{
			valid: boolean;
			msg: string;
		}>
	>;
}

export function useEfirmaValidation({
	setEfirmaValidationResponseData,
	setModalMessage,
	setShowModal,
	formHasCapcha,
	setRefreshCaptcha,
	refreshCaptcha,
	setErrorPassEfirma,
	setErrorKeyFile,
	setErrorCerFile,
}: IEfirmaValidationFunction) {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const errorServicio = 'Ocurrió un error en la validación del certificado: error';

	function scrollToTop() {
		if (pathname === '/crear-cuenta') {
			scroll.scrollToTop();
		}
	}
	const validateEfirmaFunction = (
		error: string,
		response: IResponseEfirmaValidation
	) => {
		if (error === null) {
			dispatch(setLoading({ loading: false }));
			dispatch(setLoadingRequest({ loadingRequest: false }));

			if (error === null) {
				//Respuesta
				if (response.valido) {
					const rfcValid = getRfcByEfirma(response.rfc);
					const respuesta = { ...response, rfc: rfcValid };

					setEfirmaValidationResponseData(respuesta);
				} else {
					if (response.mensajeValidacion.includes('vigente')) {
						setModalMessage(EFIRMA_MESSAGES.NOT_VALID_CERTIFICATE);
					} else if (response.mensajeValidacion.includes('emitido')) {
						setModalMessage(EFIRMA_MESSAGES.NOT_EMITED_BY_SAT);
					} else {
						setModalMessage(response.mensajeValidacion);
					}
					setShowModal(true);
					dispatch(setLoading({ loading: false }));
					dispatch(setLoadingRequest({ loadingRequest: false }));
					if (formHasCapcha && setRefreshCaptcha !== undefined) {
						setRefreshCaptcha(!refreshCaptcha);
					}
					scrollToTop();
				}
			} else {
				//Todas las validaciones pasan pero falla el servicio
				setModalMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
				setShowModal(true);
				dispatch(setLoading({ loading: false }));
				dispatch(setLoadingRequest({ loadingRequest: false }));
				if (formHasCapcha && setRefreshCaptcha !== undefined) {
					setRefreshCaptcha(!refreshCaptcha);
				}
				scrollToTop();
			}
		} else {
			scrollToTop();
			//Validaciones antes de enviarse
			if (error === errorServicio) {
				setModalMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
				setShowModal(true);
			} else {
				if (error.includes('contraseña')) {
					setErrorPassEfirma({
						valid: false,
						msg: EFIRMA_MESSAGES.INCORRECT_PSW,
					});
				} else if (error.includes('corresponden')) {
					setErrorKeyFile({
						valid: false,
						msg: EFIRMA_MESSAGES.FILES_DONT_MATCH,
					});
				} else if (error.includes('El archivo no es')) {
					if (error.includes('certificado')) {
						if (setErrorCerFile !== undefined)
							setErrorCerFile({
								valid: false,
								msg: 'El archivo no es un certificado [.cer].',
							});
					} else {
						setErrorKeyFile({
							valid: false,
							msg: 'El archivo no es un certificado [.key].',
						});
					}
				} else if (error.includes('archivos')) {
					setModalMessage(EFIRMA_MESSAGES.NOT_EFIRMA_FILES);
					setShowModal(true);
				} else {
					setModalMessage(error);
					setShowModal(true);
				}
			}
			dispatch(setLoading({ loading: false }));
			dispatch(setLoadingRequest({ loadingRequest: false }));
			if (formHasCapcha && setRefreshCaptcha !== undefined) {
				setRefreshCaptcha(!refreshCaptcha);
			}
		}
	};

	return {
		validateEfirmaFunction,
	};
}

import { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
interface Props {
	signOff: () => void;
	active: () => void;
	timeExpires: number;
}

const timeInactividad = 1000 * 300;
const timeToModal = 1000 * 60;

export const useIdle = ({ signOff, active, timeExpires }: Props) => {
	const timeout: number = timeExpires > 0 ? timeExpires : timeInactividad; //tiempo en milisegundos de espera de inactividad
	const promptBeforeIdle: number = timeToModal; //tiempo modal
	const [openModalSession, setOpenModalSession] = useState(false);
	const [remaining, setRemaining] = useState<number>(0);
	const [refreshToken, setRefreshToken] = useState<number>(0);

	useEffect(() => {
		const ejecute = () => {
			active();
			setRefreshToken(refreshToken + 1);
		};
		const interval = setInterval(ejecute, 240000);
		return () => {
			clearInterval(interval);
		};
	}, [refreshToken]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		active();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onPrompt = () => {
		setOpenModalSession(true);
		setRemaining(promptBeforeIdle);
	};

	const onIdle = () => {
		setOpenModalSession(false);
		setRemaining(0);
		signOff();
	};
	const onActive = () => {
		setOpenModalSession(false);
		setRemaining(0);
	};

	const { getRemainingTime, isPrompted, activate } = useIdleTimer({
		timeout,
		promptBeforeIdle,
		onPrompt,
		onIdle,
		onActive,
		events: [
			'keydown',
			'wheel',
			'DOMMouseScroll',
			'mousewheel',
			'mousedown',
			'touchstart',
			'touchmove',
			'MSPointerDown',
			'MSPointerMove',
			'focus',
		],
	});

	return {
		getRemainingTime,
		isPrompted,
		activate,
		remaining,
		setRemaining,
		openModalSession,
		setOpenModalSession,
		onActive,
	};
};

// Dependencies
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Resources
import { setLoading } from '../../../store/modules/loading';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { ILoginParams, ILoginResponse, IModal } from '../interfaces/auth.interface';
import { RESPONSE_CODES } from '../../../config/response-codes';
// Components
import { useNavigate } from 'react-router-dom';
import { emailValid, nrpValid, isPasswordLogin } from '../../../utils/validators';
import { IAlert } from '../../../interfaces/alert.interface';
import styles from '../style.module.css';

export function useLogin() {
	const queryParams = new URLSearchParams(window.location.search);
	const register = queryParams.get('register');
	const [data, setData] = useState<ILoginParams>({ nrp: '', correo: '', password: '' });
	const [code, setCode] = useState('');
	const [codeInput, setCodeInput] = useState('');
	const [refreshCaptcha, setRefreshCaptcha] = useState(false);
	const [errorNRP, setErrorNRP] = useState({ valid: false, msg: '' });
	const [errorCorreo, setErrorCorreo] = useState({ valid: false, msg: '' });
	const [errorPassword, setErrorPassword] = useState({ valid: false, msg: '' });
	const [errorCaptcha, setErrorCaptcha] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
		icon: false,
	});
	const [openModal, setOpenModal] = useState(false);
	const [modal, setModal] = useState<IModal>({
		title: '',
		open: false,
		body: '',
		type: '',
		labelButton: '',
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		execute,
		value: valueLogin,
		status,
	} = useApiRequest<ILoginParams, ILoginResponse>({
		path: 'autentica/auth',
		baseURL: process.env.REACT_APP_API_URL,
	});

	useEffect(() => {
		if (register !== undefined && register !== null) {
			setOpenModal(true);
			if (register === 'true') {
				setModal({
					open: true,
					body: (
						<>
							El usuario se activó correctamente. <br /> <br />
							Ya puedes iniciar sesión con tu información.
						</>
					),
					type: 'successful',
					labelButton: 'Continuar',
				});
			} else {
				setModal({
					open: true,
					body: (
						<>
							Algo salió mal con la activación de esta cuenta.
							<br /> <br /> Intenta de nuevo más tarde.
						</>
					),
					type: 'error',
					labelButton: '',
				});
			}
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		handleValidateLogin();
	}, [valueLogin?.code, status]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleValidateLogin = () => {
		if (status === 'success' && valueLogin?.code === RESPONSE_CODES.SUCCESSFUL) {
			dispatch(setLoading({ loading: false }));
			sessionStorage.setItem('userData', JSON.stringify(valueLogin.data));

			loginSuccess();
			navigate('inicio');
		} else if (
			(status === 'error' || status === 'success') &&
			valueLogin?.code === '' &&
			valueLogin?.internalCode === '' &&
			valueLogin.data === null
		) {
			setOpenModal(true);
			setModal({
				open: true,
				body:
					valueLogin?.message ||
					'Por el momento los servicios no se encuentran disponibles. Intenta más tarde.',
				type: 'error',
			});
		} else if (
			(status === 'success' || status === 'error') &&
			valueLogin?.code !== RESPONSE_CODES.SUCCESSFUL
		) {
			dispatch(setLoading({ loading: false }));
			sessionStorage.removeItem('userData');
			sessionStorage.removeItem('key');
			sessionStorage.removeItem('servicios');
			hasLoginError();
		}
	};

	const loginSuccess = () => {
		setRefreshCaptcha(!refreshCaptcha);
	};

	const hasLoginError = () => {
		setRefreshCaptcha(!refreshCaptcha);
		switch (valueLogin?.code) {
			case '0003':
				setAlert({
					show: true,
					message: (
						<span className={styles.messageError}>
							Esta cuenta está inactiva
							<br />
							<a href="/activa-registro" style={{ color: '#293990' }}>
								¡Actívala aquí!
							</a>
						</span>
					),
					severity: 'error',
					icon: false,
				});
				break;
			case '0004':
				setErrorPassword({ valid: false, msg: valueLogin.message });
				break;
			case '0005':
				setErrorNRP({ valid: false, msg: valueLogin.message });
				break;
			case '0101':
				setOpenModal(true);
				setModal({
					open: true,
					body: 'Por motivos de seguridad es necesario que cambies tu contraseña',
					type: 'error',
					labelButton: 'Cambiar contraseña',
				});
				break;
			case '0100':
				setOpenModal(true);
				setModal({
					open: true,
					body: valueLogin?.message,
					type: 'error',
				});
				break;
			case '9000':
				setOpenModal(true);
				setModal({
					open: true,
					body: valueLogin?.message,
					type: 'error',
				});
				break;
			case '9001':
				setOpenModal(true);
				setModal({
					open: true,
					body: valueLogin?.message,
					type: 'error',
				});
				break;
			default:
				setErrorCorreo({ valid: false, msg: valueLogin?.message! });
		}
	};

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setAlert({
			show: false,
			message: '',
			severity: 'success',
			icon: false,
		});
		if (name === 'nrp') {
			let validate = { valid: false, msg: '' };
			if (value.length > 11) return;
			validate = nrpValid(value);
			setErrorNRP(validate);
		}
		if (name === 'correo') {
			if (value.length > 60) {
				return;
			} else {
				setErrorCorreo(emailValid(value));
			}
		}
		if (name === 'password') {
			if (value.length > 12) {
				return;
			} else {
				setErrorPassword(isPasswordLogin(value));
			}
		}

		setData({ ...data, [name]: value });
	};

	const handleOnSubmit = () => {
		dispatch(setLoading({ loading: true }));
		execute({ ...data });
	};

	const handleValidate = (): void => {
		setErrorCorreo({ valid: false, msg: '' });
		setErrorPassword({ valid: false, msg: '' });
		if (!errorNRP.valid || !data.nrp) {
			return setErrorNRP(nrpValid(data.nrp));
		} else if (!errorCorreo.valid || !data.correo) {
			return setErrorCorreo(emailValid(data.correo));
		} else if (!data.password) {
			return setErrorPassword({ valid: false, msg: 'Escribe tu contraseña' });
		} else if (!code || codeInput !== code) {
			setRefreshCaptcha(!refreshCaptcha);
			return setErrorCaptcha(true);
		}
		setAlert({
			show: false,
			message: '',
			severity: 'success',
			icon: false,
		});
		handleOnSubmit();
	};

	return {
		data,
		errorNRP,
		errorCorreo,
		errorPassword,
		errorCaptcha,
		codeInput,
		refreshCaptcha,
		alert,
		modal,
		openModal,
		valueLogin,
		status,
		register,
		setCode,
		handleSetData,
		setCodeInput,
		handleValidate,
		setErrorCaptcha,
		setOpenModal,
	};
}

import { Suspense, LazyExoticComponent, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Layout } from '../components';
import ModalLoading from '../components/loading.component';
import LoadingRouter from '../components/loading-router.component';
import PrivateRoutes from './protected';

//routes
import { procedures } from './procedures';
import { queries } from './queries';
import { attentionCenter } from './attention-center';
import { routesPublic } from './routes-public';
import { routes } from './routes';
import ModalRequestLoading from '../components/loading-request.component';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import ErrorBoundary from './errorBundary';
import ReactGAV4 from 'react-ga4';
import { getProfile } from '../utils/getProfile';
type JSXComponent = () => JSX.Element;
interface IRoute {
	path: string;
	to: string;
	isPrivate?: boolean;
	name: string;
	Component: LazyExoticComponent<JSXComponent> | JSXComponent;
}

export const Router = () => {
	const perfil = useSelector((state: RootState) => state.routeMod);
	const { idSistemaArr } = useSelector((state: RootState) => state.idSistema);
	const profile = getProfile();
	const location = useLocation();
	let arrId: any[] = [];
	let routeModified: IRoute[] = [];

	if (profile) {
		routeModified = perfil === 'RL' ? routes : routes.slice(0, 2);
	} else {
		routeModified = routes;
	}
	//servicios
	if (idSistemaArr.length > 0) {
		arrId = idSistemaArr;
	} else {
		const servicios = sessionStorage?.getItem('servicios');
		arrId = JSON.parse(servicios!) || [];
	}
	useEffect(() => {
		if (process.env.REACT_APP_ENV === 'production') {
			ReactGAV4.send({ hitType: 'pageview', page: location.pathname });
		}
	}, [location]);

	return (
		<>
			<Routes>
				{routesPublic.map(({ path, Component }) => (
					<Route key={path} path={path} element={<Component />} />
				))}
				<Route path="*" element={<Layout />}>
					<Route path="*" element={<Navigate to="/inicio" />} />
				</Route>
				<Route path="*" element={!profile && <Navigate to="/" />} />
				<Route element={<PrivateRoutes />}>
					<Route element={<Layout />}>
						{routeModified.map(({ path, Component }) => (
							<Route
								key={path}
								path={path}
								element={
									<ErrorBoundary>
										<Suspense fallback={<LoadingRouter />}>
											<Component />
										</Suspense>
									</ErrorBoundary>
								}
							/>
						))}
						{procedures.map(({ path, Component, idSistema }) => {
							if (arrId.includes(idSistema)) {
								return (
									<Route
										key={path}
										path={path}
										element={
											<ErrorBoundary>
												<Suspense fallback={<LoadingRouter />}>
													<Component />
												</Suspense>
											</ErrorBoundary>
										}
									/>
								);
							}
							return null;
						})}
						{queries.map(({ path, Component, idSistema }) => {
							if (arrId.includes(idSistema)) {
								return (
									<Route
										key={path}
										path={path}
										element={
											<ErrorBoundary>
												<Suspense fallback={<LoadingRouter />}>
													<Component />
												</Suspense>
											</ErrorBoundary>
										}
									/>
								);
							}
							return null;
						})}
						{attentionCenter.map(({ path, Component, idSistema }) => {
							if (arrId.includes(idSistema)) {
								return (
									<Route
										key={path}
										path={path}
										element={
											<Suspense fallback={<LoadingRouter />}>
												<Component />
											</Suspense>
										}
									/>
								);
							}
							return null;
						})}
					</Route>
				</Route>
			</Routes>
			<ModalLoading />
			<ModalRequestLoading />
		</>
	);
};

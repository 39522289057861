import React, { useEffect, useState } from 'react';
import acceptedIcon from '../assets/svg/checkbox-circle-fill.svg';
import unacceptedIcon from '../assets/svg/minus-circle-fill.svg';
import styles from './styles/password-styles.component.module.css';

interface IPassword {
	password: string;
}

const PasswordComponent = ({ password }: IPassword) => {
	const [passwordStructure, setPasswordStructure] = useState({
		upperCase: false,
		lowerCase: false,
		number: false,
		specialCharacter: false,
		length10: false,
	});

	useEffect(() => {
		verifyPasswordStructure(password);
	}, [password]);

	const verifyPasswordStructure = (password: string) => {
		const upperCaseLetters = /[A-Z]/g;
		const specialCharacter = /[*@!#~{}";:¨?¿¡$,._'-]/g;
		const numbers = /[0-9]/g;
		const lowerCaseLetters = /[a-z]/g;

		if (password.match(upperCaseLetters)) {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				upperCase: true,
			}));
		} else {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				upperCase: false,
			}));
		}
		if (password.match(lowerCaseLetters)) {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				lowerCase: true,
			}));
		} else {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				lowerCase: false,
			}));
		}

		if (password.match(numbers)) {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				number: true,
			}));
		} else {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				number: false,
			}));
		}

		if (password.match(specialCharacter)) {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				specialCharacter: true,
			}));
		} else {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				specialCharacter: false,
			}));
		}

		if (password.length >= 10) {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				length10: true,
			}));
		} else {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				length10: false,
			}));
		}
	};

	return (
		<div className={styles.content}>
			<span className={styles.elementosContraseñaTitulo}>
				Recuerda usar estos elementos en tu contraseña
			</span>
			<div className={styles.elementosContraseña}>
				<span className={styles.elementosContraseñaItem}>
					{passwordStructure.upperCase ? (
						<img src={acceptedIcon} alt="icono-aceptado" />
					) : (
						<img src={unacceptedIcon} alt="icono-no-aceptado" />
					)}
					Una mayúscula
				</span>
				<span className={styles.elementosContraseñaItem}>
					{passwordStructure.lowerCase ? (
						<img src={acceptedIcon} alt="icono-aceptado" />
					) : (
						<img src={unacceptedIcon} alt="icono-no-aceptado" />
					)}
					Una minúscula
				</span>
				<span className={styles.elementosContraseñaItem}>
					{passwordStructure.number ? (
						<img src={acceptedIcon} alt="icono-aceptado" />
					) : (
						<img src={unacceptedIcon} alt="icono-no-aceptado" />
					)}
					Un número
				</span>
				<span className={styles.elementosContraseñaItem}>
					{passwordStructure.specialCharacter ? (
						<img src={acceptedIcon} alt="icono-aceptado" />
					) : (
						<img src={unacceptedIcon} alt="icono-no-aceptado" />
					)}
					Un caracter especial (#,$,@)
				</span>
				<span className={styles.elementosContraseñaItem}>
					{passwordStructure.length10 ? (
						<img src={acceptedIcon} alt="icono-aceptado" />
					) : (
						<img src={unacceptedIcon} alt="icono-no-aceptado" />
					)}
					Diez caracteres mínimo
				</span>
			</div>
		</div>
	);
};

export default PasswordComponent;
